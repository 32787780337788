import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Row } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Text } from "components/dist/styles"
import { Gutter } from "components/dist/styles"
import { Authentication as Auth } from 'components';
import { getEntitySettings, signUpRequest } from '../../providers/ReduxProvider/actions/userActions';
import { __ } from '../../utils/appHelper'
import { T } from "../../utils/translations"
import getErrorText from "../../utils/getErrorText";
import { api } from "../../providers/ApiProvider";
import { signupValidationSchema } from './validation';
import { parse } from 'query-string'
import { THEME_CUSTOM, THEME_LIGHT } from '../../utils/constants';
import { isLight } from '../../utils/styleUtils';
import Logo from '../../components/common/Logo';

const textCommons = { T, __, getErrorText }

const { Title } = Typography;

const SignUpPage = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [entity, setEntity] = useState(null)
  const entitySettings = useSelector((state) => state?.ui?.entitySettings);
  const theme = useSelector((state) => state?.ui?.theme);
  const customColors = useSelector((state) => state?.ui?.customColors);
  const entityName = props.match.params.entityName;

  const search = parse(props.location.search)

  useEffect(() => {
    api.get(`register/availableEntities/${entityName}`).then((response) => {
      setEntity(response.data)
      props.getEntitySettings(response.data.id);
      setIsLoading(false);
    });
  }, []);

  const { userRegistration } = entitySettings || {}
  const { fields, allowedEmailDomains } = userRegistration || {}

  const handleSubmit = (entity, values) => {
    const search = parse(props.location.search);
    props.signUpRequest(
      entity,
      values,
      search?.url,
      userRegistration.onSuccessfulSignUp,
      search.allow !== "signup");
  };

  return (
    <Row>
      <div
        className="col-xs-12 col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3 col-lg-6 col-lg-offset-3 col-xl-2 col-xl-offset-5">
        {
          (!isLoading) &&
          <div>
            {
              (userRegistration?.selfRegistrationEnabled) ?
                (
                  <>
                    <Logo
                      light={!(theme === THEME_LIGHT || (theme === THEME_CUSTOM && isLight(customColors.primary)))}
                    />
                    <Text center>
                      <Gutter bottom>
                        <h3>{entity?.name || ""}</h3>
                      </Gutter>
                    </Text>
                    <Auth.SignUpForm
                      signUp={handleSubmit}
                      entity={entity}
                      userRegistration={userRegistration}
                      showBackUrl={search.allow !== "signup"}
                      backUrl="/login"
                      validationSchema={fields && signupValidationSchema(fields, allowedEmailDomains)}
                      textCommons={textCommons}
                      {...props}
                    />
                  </>
                )
                : (
                  <>
                    <Title level={3}>
                      {__('Self registration is not enabled, please contact the management')}
                    </Title>
                  </>
                )
            }
          </div>
        }
      </div>
    </Row>
  );
};

export default connect(
  (state) => {
    return {
      availableLanguages: state.ui.availableLanguages,
      language: state.ui.language,
      signUpError: state.errors.signUpError,
    };
  },
  { signUpRequest, getEntitySettings }
)(SignUpPage);


SignUpPage.propTypes = {
  signUpRequest: PropTypes.func,
  signUpError: PropTypes.string,
  isLoading: PropTypes.bool,
  location: PropTypes.object,
  getEntitySettings: PropTypes.func,
};
