import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Redirect } from "react-router-dom";
import { Authentication as Auth } from 'components';
import { login, loginErrorActionCreator } from '../providers/ReduxProvider/actions/userActions';
import { __ } from '../utils/appHelper'
import { T } from "../utils/translations"
import getErrorText from "../utils/getErrorText";

import { THEME_CUSTOM, THEME_LIGHT } from "../utils/constants";
import { isLight } from "../utils/styleUtils";
import Logo from "../components/common/Logo";
import { fetchAvailableEntities } from '../providers/ApiProvider/authentication';
import { parse } from 'query-string'
import { Gutter } from 'components/dist/styles';

const textCommons = { T, __, getErrorText }

const LoginPage = (props) => {
  const { login, loginError, editLoginError } = props

  const [showSignUp, setShowSignUp] = useState(null)

  const token = useSelector((state) => state?.user?.auth?.token);
  const theme = useSelector((state) => state?.ui?.theme);
  const customColors = useSelector((state) => state?.ui?.customColors);
  const entityNameForSignUp = useSelector((state) => state?.ui?.entityNameForSignUp);

  const search = parse(props.location.search)

  useEffect(() => {
    editLoginError(null)
    fetchAvailableEntities().then((entities) => setShowSignUp(entities?.length > 0))
  }, [])

  const handleSubmit = (values) => {
    login({ values });
  }

  return (
    <>
      <Gutter bottom>
        <div className='row'>
          <div className="col-xs-12 col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3 col-lg-6 col-lg-offset-3 col-xl-2 col-xl-offset-5">
            <Logo
              light={!(theme === THEME_LIGHT || (theme === THEME_CUSTOM && isLight(customColors.primary)))}
            />
          </div>
        </div>
      </Gutter>

      <div className='row'>
        <div className="col-xs-12 col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3 col-lg-6 col-lg-offset-3 col-xl-2 col-xl-offset-5">

          {
            (!token) ?
              (
                <Auth.LoginForm
                  loginError={loginError}
                  handleSubmit={handleSubmit}
                  showSignUp={search.allow !== "login" && showSignUp}
                  signUpUrl={`sign-up/${entityNameForSignUp}`}
                  textCommons={textCommons}
                />
              )
              :
              (
                <Redirect to={"logged"} />
              )
          }
        </div>
      </div>
    </>
  );
}

export default connect(
  (state) => {
    return {
      loginError: state.errors.loginError
    }
  },
  { login, editLoginError: loginErrorActionCreator }
)(LoginPage);


LoginPage.propTypes = {
  login: PropTypes.func,
  loginError: PropTypes.string,
  location: PropTypes.object
};
